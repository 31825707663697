<template>
  <v-container id="error-view" class="fill-height text-center" tag="section">
    <v-row justify="center">
      <v-col cols="4">
        <h1 class="text-h1 warning--text mb-16 font-weight-bold">
          404
        </h1>

        <p class="body-1 white--text font-weight-bold pt-8 pb-10">
          It's looking like you may have taken a wrong turn. Don't worry... it
          happens to the best of us. You might want to check your internet
          connection.
        </p>

        <v-btn color="alert" dark depressed large to="/">
          Get me out of here!
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "NotFound",
  created() {
    this.$store.commit("ui/ERROR_PAGE");
  },
  beforeDestroy() {
    this.$store.commit("ui/ERROR_PAGE");
  },
};
</script>
<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
